<template>
  <div class="container find">
    <h3 class="tit" style="margin: 25px 0">音乐鉴赏</h3>
    <div id="station0" class="station">
      <div class="title" v-for="(item, index) in musicList" :key="index">
        <div id="title0" class="subtitle">
          <b>{{ index + 1 }}.</b>
          {{item.text}}
        </div>
        <div id="play0" class="playicon">
          <svg
            t="1498544434151"
            class="icon"
            style=""
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2373"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
          >
            <path
              d="M250.7 699.8l-17-26.3c-4.3-4.4-10.2-7.1-16.7-7.1-12.9 0-23.4 10.5-23.4 23.4 0 7 3.3 13.1 8.2 17.4 1.8 2.9 3.6 5.9 5.4 8.8 0.2 0.6 0.7 1 1 1.6 2.1 3.4 8.2 8.8 8.2 8.8 3.7 2.4 7.8 4.1 12.4 4.1 12.9 0 22.9-10.5 23.4-23.4C252.3 704.6 251.7 702.5 250.7 699.8zM421 826.7l0.2-0.6c-49.4-15-93.4-42-129.1-77.5 0 0-12.9-11.9-22-11.9-12.9 0-23.4 10.5-23.4 23.4 0 7.5 3.8 13.9 9.3 18.2l-0.2 0.1c40 40.8 89.5 72.1 145.2 90.2 0.3 0.2 0.7 0.2 1.1 0.4 1.2 0.4 2.2 0.9 3.4 1.2l0.2-0.5c1.6 0.3 3.1 1 4.8 1 12.9 0 23.4-10.5 23.4-23.4C434 838.3 428.6 830.6 421 826.7zM512 28.4C244.9 28.4 28.4 244.9 28.4 512c0 267.1 216.5 483.6 483.6 483.6S995.6 779.1 995.6 512C995.6 244.9 779.1 28.4 512 28.4zM512 933.2C279.4 933.2 90.8 744.6 90.8 512 90.8 279.4 279.4 90.8 512 90.8S933.2 279.4 933.2 512C933.2 744.6 744.6 933.2 512 933.2zM672.7 479.2 450.2 347.7c-5.9-3.2-25.8-10.7-41.1-2.1s-18.8 19.5-18.8 26.7l0 285.6c0 4.8-1.9 20 19.7 26.9s36.4-1 40.2-2.3l222.5-131.5c8.1-4.8 18-15.5 18-37.1S680 484.4 672.7 479.2zM452.7 608.7 452.7 421.5l156 93.6L452.7 608.7z"
              p-id="2374"
              fill="#20a0ff"
            ></path>
          </svg>
        </div>
      </div>
      <!-- <Page :total="100" style="margin-left: 180px; margin-top: 20px" /> -->
    </div>
  </div>
</template>
<style lang="scss">
.find {
  padding: 20px 0 40px;
}
.station {
  position: relative;
  width: 100%;
  height: 15%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -webkit-box-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 14%;
  cursor: pointer;
  box-sizing: border-box;
}
.title {
  font-size: 16px;
  height: 50px;
  line-height: 48px;
  font-weight: 300;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
  position: relative;
}
.subtitle {
  display: inline-block;
  vertical-align: middle;
}
.playicon {
  position: absolute;
  right: 25%;
  top: 10%;
  height: 35px;
}
</style>
<script>
export default {
  data() {
    return {
      musicList: [
        { text: '结婚进行曲', value: '' },
        { text: '曙光 ', value: '' },
        { text: "Can't Stop Love", value: '' },
        { text: '泡泡糖', value: '' },
        { text: '绿光', value: '' },
        { text: '梦中的婚礼', value: '' },
        { text: ' 一个人的午后', value: '' },
        { text: '大城小爱', value: '' },
        { text: '不灭青春', value: '' },
        { text: '中央C', value: '' },
        { text: '青花瓷', value: '' },
        { text: '星幻', value: '' },
        { text: '起风了', value: '' },
        { text: ' 暖暖', value: '' },
        { text: '不灭', value: '' },
        { text: '暧昧', value: '' },
        { text: '流星雨', value: '' },
        { text: '下雨天', value: '' },
        { text: '忘', value: '' },
        { text: '明天十分', value: '' },
        { text: '上升', value: '' },
        { text: '告别喧嚣', value: '' },
        { text: '和平生活', value: '' },
        { text: '你又来', value: '' },
        { text: '想要你知道', value: '' },
        { text: '我们结婚吧', value: '' },
        { text: '终于等到你', value: '' },
      ],
    }
  },
}
</script>
